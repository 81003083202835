import Service from '@/js/service.js';

const service = new Service(`${process.env.VUE_APP_CUSTOMER_API_SERVICE}/customer-api-service`);

const customerApiService = {
    getDocumentation(then, error) {
        service.getRequest(
            1,
            'documentation',
            null,
            then,
            error,
        );
    },
};

export default customerApiService;